.leftColumn {
	width: 75%;
	display: inline;
	float: left;
}

.rightColumn {
	width: 25%;
	display: inline;
	float: left;
}

